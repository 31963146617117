export const nav = {
    'NAV': {
        'ROLES':'Roles',
        'USERS':'Users',
        'AUDIT':'Documents audit',
        'LOGIN_AUDIT':'Login audits',
        'DOCUMENT':'All documents',
        'CATEGORY': 'All categories',
        'SETTINGS':"Settings",
        'REMINDERS':'Reminders',
        'CALENDAR':'Calendar',
        'CHAT':'Chat',
        'TEAM':'Team',

        'LANGUAGES':'Languages',
        'COMPANY_PROFILE':'Company profile',
        'SMTP':"SMTP",
        'DASHBOARD': 'Dashboard',
        'DASHBOARD_DEFAULT': 'Default',
        'ASSIGNED_DOCUMENT':'Assigned documents',
        'APPS': 'Apps',
        'APPS_MAIL': 'Mail',
        'APPS_CHAT': 'Chat',
        'APPS_CALENDAR': 'Calendar',
        'COMPONENTS': 'Components',
        'COMPONENTS_UIELEMENTS': 'UI Elements',
        'COMPONENTS_UIELEMENTS_ACCORDION': 'Accordion',
        'COMPONENTS_UIELEMENTS_ALERT': 'Alert',
        'COMPONENTS_UIELEMENTS_BUTTONS': 'Buttons',
        'COMPONENTS_UIELEMENTS_CAROUSEL': 'Carousel',
        'COMPONENTS_UIELEMENTS_COLLAPSE': 'Collapse',
        'COMPONENTS_UIELEMENTS_DATEPICKER': 'Datepicker',
        'COMPONENTS_UIELEMENTS_DROPDOWN': 'Dropdown',
        'COMPONENTS_UIELEMENTS_MODAL': 'Modal',
        'COMPONENTS_UIELEMENTS_PAGINATION': 'Pagination',
        'COMPONENTS_UIELEMENTS_POPOVER': 'Popover',
        'COMPONENTS_UIELEMENTS_PROGRESSBAR': 'Progressbar',
        'COMPONENTS_UIELEMENTS_RATING': 'Rating',
        'COMPONENTS_UIELEMENTS_SORTABLE': 'Sortable',
        'COMPONENTS_UIELEMENTS_TABS': 'Tabs',
        'COMPONENTS_UIELEMENTS_TIMEPICKER': 'Timepicker',
        'COMPONENTS_UIELEMENTS_TOOLTIP': 'Tooltip',
        'COMPONENTS_UIELEMENTS_TYPEHEAD': 'Typehead',
        'COMPONENTS_FORMELEMENTS': 'Form Elements',
        'COMPONENTS_FORMELEMENTS_INPUT': 'Input',
        'COMPONENTS_FORMELEMENTS_RADIO': 'Radio',
        'COMPONENTS_FORMELEMENTS_CHECKBOX': 'Checkbox',
        'COMPONENTS_FORMELEMENTS_SELECT': 'Select',
        'COMPONENTS_FORMELEMENTS_FORM': 'Form',
        'COMPONENTS_FORMELEMENTS_SWITCH': 'Switch',
        'COMPONENTS_FORMELEMENTS_UPLOAD': 'Upload',
        'COMPONENTS_TABLES': 'Tables',
        'COMPONENTS_TABLES_TABLE': 'Table',
        'COMPONENTS_TABLES_DATATABLE': 'Data Table',
        'COMPONENTS_ICONS': 'Icons',
        'COMPONENTS_ICONS_FEATHER': 'Feather',
        'COMPONENTS_ICONS_LINE_AWESOME': 'Line Awesome',
        'COMPONENTS_MAPS': 'Maps',
        'COMPONENTS_MAPS_AMMAP': 'AmMap',
        'COMPONENTS_MAPS_GOOGLE_MAP': 'Google Map',
        'CHART': 'Chart',
        'PAGES': 'Pages',
        'PAGES_UTILITY': 'Utility',
        'PAGES_UTILITY_PROFILE': 'Profile',
        'PAGES_UTILITY_INVOICE': 'Invoice',
        'PAGES_UTILITY_FAQ': 'FAQ',
        'PAGES_UTILITY_PRICING': 'Pricing',
        'PAGES_UTILITY_USERLIST': 'User List',
        'PAGES_AUTH': 'Auth',
        'PAGES_AUTH_LOGIN': 'Login',
        'PAGES_AUTH_LOGIN_V2': 'Login v2',
        'PAGES_AUTH_LOGIN_V3': 'Login v3',
        'PAGES_AUTH_REGISTER': 'Register',
        'PAGES_AUTH_REGISTER_V2': 'Register v2',
        'PAGES_AUTH_REGISTER_V3': 'Register v3',
        'PAGES_ERRORS': 'Errors',
        'PAGES_ERRORS_ERROR1': 'Error 1',
        'PAGES_ERRORS_ERROR2': 'Error 2',
        'OTHERS': 'Others',
        'OTHERS_DOCUMENTATION': 'Documentation',
        'OTHERS_CHANGELOG': 'Changelog'
    }
}