import { nav } from './section/nav'


export const fr_FR = { 
    'CONNECTION_STATUS':'Actif il y a ',
    'CONNECTION_STATUS_NOW':'Online',
    'SELECTED_FILE':'Un fichier sélectionné',
    'NEWCHAT':'Nouvelle discussion',
    'EDIT':'Modifier',
    'RESET_PASSWORD':'Réinitialiser le mot de passe',
    'PERMESSION':'Autorisation',
    'DELETE':'Supprimer',
    'UPDATE':'Modifier',
    'DATETIME':'Date & Heure',
    'USERNAME':'Email',
    'REMOETIP':'IP',
    'AUTHOR':'Crée par',
    'CURRENT_VERSION':'Version actuelle',
    'STATUS':'Status',
    'LATITUDE':'Latitude',
    'LONGITUDE':'Longitude', 	
    'DESCREPTION':'Descreption',
    'CATEGORY':'Catégorie',
    'FILE':'Fichier', 
    'MAIL_SENT':'Email envoyé.', 
    'SEND':'Envoyer',
    'USER_ROLE_NAME':"Nom d'utilisateur/rôle",
    'START_DATE':'Date de début',
    'END_DATE':'Date de fin', 
    'TYPE':"Type",
    'SUBJECT':'Sujet',
    'MESSAGE':'Message',
    'FREQUENCY':'Fréquence',
    'DOCUMENT':'Document', 
    'DOCUMENT_ADDED':'Document ajouté avec succès.',
    'ASSIGN_SHARE_WITH_ROLES':'Attribuer un partage avec des rôles',
    'SPACIFY_THE_PERIOD':'Spacifier la période',
    'ALLOW_DOWNLOAD':'Autoriser le téléchargement',
    'ASSIGN_SHARE_WITH_USERS':'Attribuer un partage avec les utilisateurs',
    'HOST':'Host',
    'CHAT':'Messages',
    'TEAM':'Équipe',
    'DOWNLOAD':'Téléchargez le fichier',
    'REMINDER_DATE':'Date de rappel',
    'REMINDER_END_DATE':'Date de fin du rappel',
    'SELECT_QUARTER_DATE':'Sélectionnez la date du trimestre',
    'SEND_MAIL':'Envoyer un mail',
    'REPEATE_REMINDER':'Répéter le rappel',
    'USERS':'Utilisateurs',
    'PORT':'Port',
    'ENCRYPTION':'Chiffrement',
    'USER_NAME':"Nom d'utilisateur",
    'FROM_NAME':'De',
    'IS_DEFAULT':'Définir par défaut',
    'ADD':"Ajouter", 
    'PASSWORD_MATCH':'les mots de passe ne correspondent pas',
    'SERVER_SUCCESS':'Demande enregistrée avec succès.',
    'ERROR_SERVER':"Une erreur s'est produite. Veuillez réessayer.",
    'PASSWORD_UPDATE':'Mot de passe mis à jour avec succès.',
    'Name':'Nom',
    'PASSWORD':"Mot de passe",
    'CONFIRM_PASSWORD':"Confirmer mot de passe",
    'ROLES':'Roles',
    'EMAIL':'Email',
    'FIRSTNAME':"Prénom",
    'LASTNAME':'Nom',
    'PHONE':'Télephone',
    'CATEGORY_NAME':'Nom de catégorie',
    'OPERATION':'Opération',
    'BY_WHOM':'Par qui ?',
    'TO_WHOM_USER':'A qui ?',
    'TO_WHOM_ROLE':'à quelle rôle',
    'ACTIONDATE':"Date d'inervention",
    'Back':'Retour',
    'Add new':'Ajouter',
    'Description':'Description',
    'Update':'Modifier',
    'DELETEMESSAGE':"Êtes-vous sûr de bien vouloir supprimer cet élément ?",
    'WAITALERT':'Confirmation',
    'CONFIRM':'Confirmer',
    'UPLOADING':'Chargement en cours...',
    'overviewTransactionDocument': 'Aperçu de la transaction des documents',
    'FileExtensionOverview':'Aperçu des extensions de fichiers ',
    'categoryOverview':'Aperçu des catégories',
    'fullName': 'Nom et prénom',
    'Recent_Users': 'Utilisateurs récents',
    ...nav
};
